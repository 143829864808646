import routes from './routes'

export default {
  // dashboard: {
  //   name: 'Dashboard',
  //   route: routes.dashboard,
  // },
  adminPanel: {
    name: 'Admin Panel',
    route: routes.adminPanel.root,
    permissions: {
      Currencies: {
        name: 'Currencies',
        get_currencies: 'Retrieve all currencies',
        create_currency: 'Create new currency',
        delete_currency: 'Delete specific currency',
        edit_currency: 'Update specific currency',
      },
      Countries: {
        name: 'Countries',
        get_countries: 'Retrieve all countries',
        create_countries: 'Create new country',
        delete_countries: 'Delete specific country',
        edit_countries: 'Update specific country',
      },
      Regions: {
        name: 'Regions',
        get_regions: 'Retrieve all regions',
        create_region: 'Create new region',
        delete_region: 'Delete specific region',
        edit_region: 'Update specific region',
      },
      Languages: {
        name: 'Languages',
        get_languages: 'Retrieve all languages',
        create_language: 'Create new language',
        delete_language: 'Delete specific language',
        edit_language: 'Update specific language',
      },
      Certificates: {
        name: 'Company certifications',
        get_certificates: 'Retrieve all company certifications',
        create_certificate: 'Create new company certification',
        delete_certificate:
          'Delete specific company certification',
        edit_certificate:
          'Update specific company certification',
      },
    },
  },
  companyManagement: {
    name: 'Companies',
    route: routes.companyManagement.root,
    permissions: {
      Companies: {
        name: 'Companies',
        create_company: 'View All Companies',
        edit_company: 'Update a Company',
        delete_company: 'Delete a Company',
      },
      company_remove: {
        name: 'Deleted companies',
        archive_company:
          'Archive Company',
        unarchive_company:
          'Unarchive Company',
      },
      company_certificates: {
        name: 'Company Certificates',
        create_certificate: 'Create a Company Certificate',
        edit_certificate:
          'Update a Company Certificate',
        delete_certificate:
          'Delete a Company Certificate',
        validate_certificate:
          'Validate a Company Certificate',
      },
      company_logo: {
        name: 'Company logo',
        create_logo: 'Upload Company Logo',
        delete_logo: 'Delete Company Logo',
      },
      related_companies: {
        name: 'Related companies',
        get_linked_companies:
          'View Linked Companies',
        get_requests: 'View Company Link Requests',
        create_request: 'Create Company Link Request',
        approve_request:
          'Approve Company Link Request',
        decline_request:
          'Decline Company Link Request',
      },
      Users: {
        name: 'Users',
        get_users: 'View All Users',
        create_user: 'Create User',
        edit_user: 'Edit User',
        delete_user: 'Delete User',
        get_company_users: 'View Company Users',
      },
      user_remove: {
        name: 'Deleted users',
        archive: 'Archive User',
        unarchive: 'Unarchive User',
      },
    },
  },
  myCompany: {
    name: 'My Company',
    route: routes.myCompany.root,
    permissions: {
      Companies: {
        name: 'Companies',
        create_company: 'View All Companies',
        edit_company: 'Update a Company',
        delete_company: 'Delete a Company',
      },
      company_remove: {
        name: 'Deleted companies',
        archive_company:
          'Archive Company',
        unarchive_company:
          'Unarchive Company',
      },
      company_certificates: {
        name: 'Company Certificates',
        create_certificate: 'Create a Company Certificate',
        edit_certificate:
          'Update a Company Certificate',
        delete_certificate:
          'Delete a Company Certificate',
        validate_certificate:
          'Validate a Company Certificate',
      },
      company_logo: {
        name: 'Company logo',
        create_logo: 'Upload Company Logo',
        delete_logo: 'Delete Company Logo',
      },
      related_companies: {
        name: 'Related companies',
        get_linked_companies:
          'View Linked Companies',
        get_requests: 'View Company Link Requests',
        create_request: 'Create Company Link Request',
        approve_request:
          'Approve Company Link Request',
        decline_request:
          'Decline Company Link Request',
      },
      Users: {
        name: 'Users',
        get_users: 'View All Users',
        create_user: 'Create User',
        edit_user: 'Edit User',
        delete_user: 'Delete User',
        get_company_users: 'View Company Users',
      },
      user_remove: {
        name: 'Deleted users',
        archive: 'Archive User',
        unarchive: 'Unarchive User',
      },
    },
  },
  orders: {
    name: 'Orders',
    route: routes.quality_control.orders.root,
    permissions: {
      products: {
        name: 'Production order products',
        create_product:
          'View Production Order Products',
        edit_product:
          'Update Production Order Products',
        delete_product:
          'Delete Production Order Products',
        get_products:
          'View Production Order Products',
        get_instances:
          'View Production Order Product Instances',
        add_instaces:
          'Add Production Order Product Instances',
        delete_instance:
          'Delete Production Order Product Instances',
      },
      orders: {
        name: 'Production orders',
        create_order: 'Create new production order',
        edit_order:
          'Edit specific production order',
        delete_order:
          'Delete specific production order',
        send_order:
          'Send specific production order',
      },
      import: {
        name: 'Data import',
        get_imports: 'View all imports',
        create_import: 'Create new import',
      },
    },
  },
  reports: {
    name: 'QC Reports',
    route: routes.quality_control.reports.root,
    permissions: {
      reports: {
        name: 'QC Reports',
        create_report: 'Create QC Reports',
        edit_report: 'Edit specific QC Report',
        delete_report: 'Delete specific QC Report',
        send_report: 'Send specific QC Report',
      },
      report_rows: {
        name: 'QC Report row',
        get_rows: 'View rows in specific QC Report',
        delete_row: 'Delete specific row in specific QC Report',
        create_row: 'Add rows to specific QC Report',
        get_history:
          'View history of specific row in specific QC Report',
        change_status:
          'Update status of specific row in specific QC Report',
        edit_row: 'Edit specific row in specific QC Report',
      },
      product: {
        name: 'QC Reports products',
        sync_products:
          'Synchronize products for specific production order in specific QC Report',
        sync: 'Synchronize production orders for specific QC Report',
      },
      problems: {
        name: 'QC Reports row problems',
        get_problems:
          'Permission: GET api/v1/reports/{report}/rows/{row}/problems',
        create_problem:
          'Permission: POST api/v1/reports/{report}/rows/{row}/problems',
        edit_problem:
          'Permission: PUT api/v1/reports/{report}/rows/{row}/problems/{problem}',
        delete_problem:
          'Permission: DELETE api/v1/reports/{report}/rows/{row}/problems/{problem}',
      },
    },
  },
  templates: {
    name: 'QC Reports Templates',
    route: routes.quality_control.templates.root,
    permissions: {
      templates: {
        name: 'QC Report templates',
        get_templates: 'View Report Templates',
        create_template: 'Create Report Templates',
        edit_template:
          'Edit Report Templates',
        delete_template:
          'Delete Report Templates',
      },
      fields: {
        name: 'Template custom field',
        get_fields: 'View Custom Fields',
        create_field: 'Create Custom Fields',
        edit_field: 'Edit Custom Fields',
        delete_field: 'Delete Custom Fields',
      },
      template_fields: {
        name: 'QC Report templates fields',
        change_position:
          'Edit Report Template Fields',
        add_field_to_template:
          'Add Report Template Fields',
        edit_template_field:
          'Update Report Template Fields',
        delete_template_field:
          'Remove Report Template Fields',
      },
    },
  },
  productLibrary: {
    name: 'Product Library',
    route: routes.productLibrary.root,
    permissions: {
      Collections: {
        name: 'Collections',
        get_collections: 'View Collections',
        create_collection: 'Create Collection',
        get_collection: 'View Specific Collection',
        edit_collection: 'Update Collection',
        delete_collection: 'Delete Collection',
        merge_collection: 'Merge Collections',
      },
      product_remove: {
        name: 'Deleted products',
        archive_product: 'Archive Product',
      },
      colletion_remove: {
        name: 'Deleted collections',
        archive_collection:
          'Archive/Delete Collection',
        unarchive_collection:
          'Unarchive Collection',
      },
      collection_content: {
        name: 'Collection content',
        edit_collection_content:
          'Update Collection Content',
        create_collection_content:
          'Add Collection Content',
        delete_collection_content:
          'Delete Collection Content',
      },
      collection_share: {
        name: 'Share collection',
        share: 'Share Collection',
        unshare: 'Unshare Collection',
        get_shared: 'View Collection Shares',
      },
      product_content: {
        name: 'Product contents',
        edit_product_content:
          'Update Product Content',
        create_product_content:
          'Add Product Content',
        delete_product_content:
          'Delete Product Content',
      },
      product_share: {
        name: 'Shared products',
        share: 'Share Product',
        unshare: 'Unshare Product',
        get_shared: 'View Shared Product',
      },
      Products: {
        name: 'Products',
        create_product: 'Create Product',
        edit_product: 'Update Product',
        delete_product: 'Delete Product',
      },
      variants: {
        name: 'Product variants',
        create_variant: 'Create Product Variants',
        edit_variant:
          'Update Specific Product Variant',
        delete_variant:
          'Delete Specific Product Variant',
      },
      variant_content: {
        name: 'Product variant content',
        edit_variant_content:
          'Add Product Variant Content',
        create_variant_content:
          'Update Product Variant Content in Specific Language',
        delete_variant_content:
          'Delete Product Variant Content in Specific Language',
      },
    },
  },
}
