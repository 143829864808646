import React from 'react'

import routes from 'constants/routes'
import tools from 'constants/tools'

import i18n from 'translations'

import {
  // DashboardIcon,
  ProductLibraryIcon,
  AdminPanelIcon,
  QualityControlIcon,
} from 'assets/icons/sidebar'

export const links = {
  // [tools.dashboard.name]: {
  //   name: i18n.t('sidebar:dashboard'),
  //   link: routes.dashboard,
  //   Icon: (props) => <DashboardIcon {...props} />,
  // },
  [tools.productLibrary.name]: {
    name: i18n.t('sidebar:productLibrary'),
    link: routes.productLibrary.root,
    Icon: (props) => <ProductLibraryIcon {...props} />,
  },
  [tools.companyManagement.name]: {
    name: i18n.t('sidebar:companyManagement'),
    link: routes.companyManagement.root,
    Icon: (props) => <AdminPanelIcon {...props} />,
    id: 'Companies',
  },
  quality_control: {
    name: i18n.t('sidebar:quality_control:name'),
    Icon: (props) => <QualityControlIcon {...props} />,
  },
  [tools.orders.name]: {
    name: i18n.t('sidebar:quality_control:orders'),
    link: routes.quality_control.orders.root,
    parent: 'quality_control',
  },
  [tools.reports.name]: {
    name: i18n.t('sidebar:quality_control:reports'),
    link: routes.quality_control.reports.root,
    parent: 'quality_control',
  },
  [tools.templates.name]: {
    name: i18n.t('sidebar:quality_control:templates'),
    link: routes.quality_control.templates.root,
    parent: 'quality_control',
  },
  [tools.adminPanel.name]: {
    name: i18n.t('sidebar:adminPanel'),
    link: routes.adminPanel.root,
    Icon: (props) => <AdminPanelIcon {...props} />,
  },
}

export const getActiveLinks = (activeTools, isAdmin, user) => {
  let newLinks = []

  if (isAdmin) {
    Object.values(tools).forEach((el) => {
      if (links[el.name]) {
        if (links[el.name]?.parent) {
          const parent = links[links[el.name].parent]
          const existingParentIndex = newLinks.findIndex(
            (el) => el.name === parent?.name
          )

          if (existingParentIndex >= 0) {
            return (newLinks[existingParentIndex] = {
              ...newLinks[existingParentIndex],
              subRoutes: [
                ...newLinks[existingParentIndex].subRoutes,
                links[el.name],
              ],
            })
          }

          return newLinks.push({ ...parent, subRoutes: [links[el.name]] })
        }
        newLinks.push(links[el.name])
      }
    })
  } else {
    activeTools?.forEach((tool) => {
      if (links[tool]) {
        if (links[tool]?.parent) {
          const parent = links[links[tool].parent]
          const existingParentIndex = newLinks.findIndex(
            (el) => el.name === parent?.name
          )

          if (existingParentIndex >= 0) {
            return (newLinks[existingParentIndex] = {
              ...newLinks[existingParentIndex],
              subRoutes: [
                ...newLinks[existingParentIndex].subRoutes,
                links[tool],
              ],
            })
          }

          return newLinks.push({ ...parent, subRoutes: [links[tool]] })
        }
        if (user) {
          newLinks = newLinks.map(link => {
            if (link?.id === tools.companyManagement.name) {
              return ({
                name: i18n.t('sidebar:myCompany'),
                link: `/my-company/${user?.company?.uuid}`,
                Icon: (props) => <AdminPanelIcon {...props} />,
              })
            }
            return link
          })
        }
        newLinks.push(links[tool])
      }
    })
  }

  return newLinks
}
