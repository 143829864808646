import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './CommonButton.module.scss'

const CommonButton = ({
  name = '',
  type = 'button',
  Icon = null,
  isActive = false,
  customStyle = '',
  customActive = '',
  onClick = () => null,
  disabled = false,
  color = CommonButton.GHOSTED,
}) => {
  return (
    <button
      type={type}
      className={classnames(
        color,
        isActive && [styles.activeButton, customActive],
        customStyle
      )}
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {Icon &&
        Icon({
          className: classnames(styles.icon, isActive && styles.activeIcon),
        })}
      {name}
    </button>
  )
}

CommonButton.GHOSTED = styles.ghosted
CommonButton.PRIMARY = styles.primary
CommonButton.SECONDARY = styles.secondary

CommonButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  Icon: PropTypes.func,
  isActive: PropTypes.bool,
  customStyle: PropTypes.string,
  customActive: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
}

export default CommonButton
