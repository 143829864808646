import axios from 'axios'
import {
  getTokenFromStorage,
  getRefreshTokenFromStorage,
  getLanguageFromStorage,
} from 'utils/serializer'

import { authActions } from 'store/ducks/auth'
import { commonActions } from 'store/ducks/common'

import auth from './auth'
import errorHandler from 'utils/errorHandler'

const { REACT_APP_BASE_URL } = process.env

const API = axios.create({
  baseURL: REACT_APP_BASE_URL,
})

export const addInterceptors = (store) => {
  const { dispatch } = store
  API.interceptors.request.use(
    (config) => {
      const token = getTokenFromStorage()
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      config.headers['Accept'] = 'application/json'
      config.headers['language'] = getLanguageFromStorage()
      if (config.method !== 'get' && !config.preventDefaultLoading) {
        dispatch(commonActions.setActionLoading(true))
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  let isRefreshing = false
  let failedQueue = []

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  API.interceptors.response.use(
    (response) => {
      if (response?.config?.method !== 'get') {
        dispatch(commonActions.setActionLoading(false))
      }
      return response
    },
    (err) => {
      const originalRequest = err.config

      if (err?.response?.status !== 401 && err?.response?.config !== 'get') {
        dispatch(commonActions.setActionLoading(false))
        errorHandler(err)
      }

      if (
        err?.response?.status === 401 &&
        err?.response?.data?.error_description ===
          'The refresh token is invalid.'
      ) {
        dispatch(commonActions.setActionLoading(false))
        dispatch(authActions.forceLogout())
      }

      if (err.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return axios(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }

        originalRequest._retry = true
        isRefreshing = true

        return new Promise(function (resolve, reject) {
          auth
            .userLogin({
              data: {
                refresh_token: getRefreshTokenFromStorage(),
                username: '',
                password: '',
              },
              isRefresh: true,
            })
            .then((res) => {
              localStorage.setItem('token', JSON.stringify(res.data))
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + res.data.access_token
              originalRequest.headers['Authorization'] =
                'Bearer ' + res.data.access_token
              dispatch(
                authActions.tokenRefresh({
                  data: res.data.access_token,
                })
              )
              processQueue(null, res.data.access_token)
              resolve(axios(originalRequest))
            })
            .catch((err) => {
              processQueue(err, null)
              dispatch(authActions.forceLogout())
              dispatch(commonActions.setActionLoading(false))
              errorHandler(err)
              reject(err)
            })
            .then(() => {
              isRefreshing = false
            })
        })
      }

      return Promise.reject(err)
    }
  )
}

export const formDataHeaders = {
  'Content-Type': 'multipart/form-data',
}

export const requests = {
  get: ({ url, params = {}, headers = {}, configs = {} }) =>
    API.get(url, {
      headers,
      params,
      ...configs,
    }),
  post: ({ url, data, params = {}, headers = {} }) =>
    API.post(url, data, {
      headers,
      params,
    }),
  put: ({ url, data, params = {}, headers = {}, configs = {} }) =>
    API.put(url, data, {
      headers,
      params,
      ...configs,
    }),
  delete: ({ url, params = {}, headers = {} }) =>
    API.delete(url, {
      headers,
      params,
    }),
  patch: ({ url, data, params = {}, headers = {} }) =>
    API.patch(url, data, {
      headers,
      params,
    }),
}
