const getStoneCertificates = (state) =>
  state.stoneCertificate.certificates.items || []
const getStoneCertificatesLoading = (state) =>
  state.stoneCertificate.certificates.loading
const getStoneCertificatesPages = (state) =>
  state.stoneCertificate.certificates.totalPages



export default {
  getStoneCertificates,
  getStoneCertificatesLoading,
  getStoneCertificatesPages,
}
