import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './MenuItem.module.scss'

function MenuItem({
  children,
  forwardedRef,
  onClick,
  className,
  icon,
  ...props
}) {
  return (
    <li role="none" ref={forwardedRef} className={styles.item} {...props}>
      <button
        type="button"
        className={classNames(styles.button, className)}
        onClick={onClick}
      >
        {icon && <img src={icon} alt="icon" className={styles.icon} />}
        {children}
      </button>
    </li>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onClick: PropTypes.func,
  icon: PropTypes.node,
  className: PropTypes.string,
}

export default forwardRef((props, ref) => (
  <MenuItem {...props} forwardedRef={ref} />
))
