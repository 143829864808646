import types from './actionTypes'
import productTypes from '../product/actionTypes'
import userTypes from '../user/actionTypes'
import collectionTypes from '../collection/actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  companies: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    moreLoading: false,
  },
  sharedCompanies: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    moreLoading: false,
  },
  company: {
    item: null,
    loading: true,
    error: '',
  },
  companyUsers: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    totalUsers: 0,
  },
  companyCertificates: {
    items: [],
    loading: true,
    error: '',
  },
  companiesLetters: {
    items: [],
    loading: true,
    error: '',
  },
  companyLinks: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  linkedCompanies: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
    total: 0,
  },
  globalSettings: {
    items: [],
    loading: true,
    error: '',
  },
  companyAttributesMappings: {
    items: [],
    loading: true,
    error: '',
  },
}

const companyReducer = (state = initialState, { payload, type }) => {
  let activeIndex = 0
  let newItems = []
  switch (type) {
    case PENDING(types.GET_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload?.meta?.last_page,
        },
      }
    case FAILURE(types.GET_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          items: [...state.companies.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_COMPANIES):
      return {
        ...state,
        companies: {
          ...state.companies,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          loading: true,
        },
      }
    case SUCCESS(types.GET_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload?.meta?.last_page,
        },
      }
    case FAILURE(types.GET_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          items: [...state.sharedCompanies.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_SHARED_COMPANIES):
      return {
        ...state,
        sharedCompanies: {
          ...state.sharedCompanies,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_COMPANY):
      return {
        ...state,
        company: {
          ...state.company,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANY):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_COMPANY):
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.UPDATE_COMPANY):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
        },
      }
    case SUCCESS(types.UPLOAD_COMPANY_LOGO):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
        },
      }
    case SUCCESS(types.REMOVE_COMPANY_LOGO):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
        },
      }
    case SUCCESS(types.CREATE_COMPANY_CERTIFICATE):
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: [...state.companyCertificates.items, payload.data],
        },
      }
    case SUCCESS(types.EDIT_COMPANY_CERTIFICATE):
      activeIndex = state.companyCertificates.items.findIndex(
        (el) => el.uuid === payload?.data?.uuid
      )
      newItems = state.companyCertificates.items
      if (activeIndex >= 0) {
        newItems[activeIndex] = payload?.data
      }
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_COMPANY_CERTIFICATE):
      activeIndex = state.companyCertificates.items.findIndex(
        (el) => el.uuid === payload
      )
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: [
            ...state.companyCertificates.items.slice(0, activeIndex),
            ...state.companyCertificates.items.slice(activeIndex + 1),
          ],
        },
      }
    case SUCCESS(types.DELETE_COMPANY_CERTIFICATE_FILES):
      activeIndex = state.companyCertificates.items.findIndex(
        (el) => el.uuid === payload?.data?.uuid
      )
      newItems = state.companyCertificates.items
      if (activeIndex >= 0) {
        newItems[activeIndex] = payload?.data
      }
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: [...newItems],
        },
      }
    case SUCCESS(types.UPLOAD_COMPANY_CERTIFICATE_FILES):
      activeIndex = state.companyCertificates.items.findIndex(
        (el) => el.uuid === payload?.data?.uuid
      )
      newItems = state.companyCertificates.items
      if (activeIndex >= 0) {
        newItems[activeIndex] = payload?.data
      } else {
        newItems = [payload.data]
      }
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: [...newItems],
        },
      }
    case SUCCESS(types.UPLOAD_COMPANY_FILES):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
        },
      }
    case SUCCESS(types.DELETE_COMPANY_FILES):
      return {
        ...state,
        company: {
          ...state.company,
          item: payload.data,
        },
      }
    case SUCCESS(types.UPDATE_COMPANY_IMPORT_SETTINGS):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          items: payload.data?.import_settings?.groups?.map((group) => {
            const { length, type } = group
            const [from, to] = length.split('-')

            return { from: Number(from), to: Number(to), type }
          }) || [],
        },
      }
    case SUCCESS(types.CLEAR_COMPANY_IMPORT_SETTINGS):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          items: [],
        },
      }
    case PENDING(types.GET_COMPANY_USERS):
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANY_USERS):
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload?.meta?.last_page,
          totalUsers: payload?.meta?.total,
        },
      }
    case FAILURE(types.GET_COMPANY_USERS):
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(userTypes.CREATE_USER):
      return {
        ...state,
        companyUsers: {
          ...state.companyUsers,
          items: [payload.data, ...state.companyUsers.items],
        },
      }
    case PENDING(productTypes.SHARE_PRODUCT):
      return {
        ...state,
        companies: {
          ...state.companies,
        },
      }
    case SUCCESS(productTypes.SHARE_PRODUCT):
      return {
        ...state,
        companies: {
          ...state.companies,
          items: [...payload.data, ...state.companies.items],
          error: '',
        },
      }
    case FAILURE(productTypes.SHARE_PRODUCT):
      return {
        ...state,
        companies: {
          ...state.companies,
          error: payload,
        },
      }
    case PENDING(productTypes.UNSHARE_PRODUCT):
      return {
        ...state,
        companies: {
          ...state.companies,
        },
      }
    case SUCCESS(productTypes.UNSHARE_PRODUCT):
      activeIndex = state.companies.items.findIndex(
        (el) => el.uuid === payload.companyIds[0]
      )
      return {
        ...state,
        companies: {
          ...state.companies,
          items: [
            ...state.companies.items.slice(0, activeIndex),
            ...state.companies.items.slice(activeIndex + 1),
          ],
          error: '',
        },
      }
    case FAILURE(productTypes.UNSHARE_PRODUCT):
      return {
        ...state,
        companies: {
          ...state.companies,
          error: payload,
        },
      }
    case PENDING(collectionTypes.SHARE_COLLECTION):
      return {
        ...state,
        companies: {
          ...state.companies,
        },
      }
    case SUCCESS(collectionTypes.SHARE_COLLECTION):
      return {
        ...state,
        companies: {
          ...state.companies,
          items: [...payload.data, ...state.companies.items],
          error: '',
        },
      }
    case FAILURE(collectionTypes.SHARE_COLLECTION):
      return {
        ...state,
        companies: {
          ...state.companies,
          error: payload,
        },
      }
    case PENDING(collectionTypes.UNSHARE_COLLECTION):
      return {
        ...state,
        companies: {
          ...state.companies,
        },
      }
    case SUCCESS(collectionTypes.UNSHARE_COLLECTION):
      activeIndex = state.companies.items.findIndex(
        (el) => el.uuid === payload.companyIds[0]
      )
      return {
        ...state,
        companies: {
          ...state.companies,
          items: [
            ...state.companies.items.slice(0, activeIndex),
            ...state.companies.items.slice(activeIndex + 1),
          ],
          error: '',
        },
      }
    case FAILURE(collectionTypes.UNSHARE_COLLECTION):
      return {
        ...state,
        companies: {
          ...state.companies,
          error: payload,
        },
      }
    case PENDING(types.GET_COMPANIES_LETTERS):
      return {
        ...state,
        companiesLetters: {
          ...state.companiesLetters,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANIES_LETTERS):
      return {
        ...state,
        companiesLetters: {
          ...state.companiesLetters,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_COMPANIES_LETTERS):
      return {
        ...state,
        companiesLetters: {
          ...state.companiesLetters,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          loading: true,
        },
      }
    case SUCCESS(types.GET_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload.meta.last_page,
          total: payload.meta.total,
        },
      }
    case FAILURE(types.GET_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          items: [...state.linkedCompanies.items, ...payload.data],
          error: '',
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          ...state.linkedCompanies,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_COMPANY_CERTIFICATES):
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANY_CERTIFICATES):
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          items: payload.data,
          loading: false,
          error: '',
        },
      }
    case FAILURE(types.GET_COMPANY_CERTIFICATES):
      return {
        ...state,
        companyCertificates: {
          ...state.companyCertificates,
          error: payload,
          loading: false,
        },
      }
    case PENDING(types.GET_COMPANY_LINKS):
      return {
        ...state,
        companyLinks: {
          ...state.companyLinks,
          loading: true,
        },
      }
    case SUCCESS(types.GET_COMPANY_LINKS):
      return {
        ...state,
        companyLinks: {
          ...state.companyLinks,
          items: payload.data,
          error: '',
          loading: false,
          totalPages: payload?.meta?.last_page,
        },
      }
    case FAILURE(types.GET_COMPANY_LINKS):
      return {
        ...state,
        companyLinks: {
          ...state.companyLinks,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.DECLINE_COMPANY_LINK):
      activeIndex = state.companyLinks.items.findIndex(
        (el) => el.uuid === payload
      )
      return {
        ...state,
        companyLinks: {
          ...state.companyLinks,
          items: [
            ...state.companyLinks.items.slice(0, activeIndex),
            ...state.companyLinks.items.slice(activeIndex + 1),
          ],
        },
      }
    case SUCCESS(types.APPROVE_COMPANY_LINK):
      activeIndex = state.companyLinks.items.findIndex(
        (el) => el.uuid === payload.id
      )

      if (payload?.company?.type === payload?.type) {
        return {
          ...state,
          companyLinks: {
            ...state.companyLinks,
            items: [
              ...state.companyLinks.items.slice(0, activeIndex),
              ...state.companyLinks.items.slice(activeIndex + 1),
            ],
          },
          linkedCompanies: {
            ...state.linkedCompanies,
            items: [...state.linkedCompanies.items, payload.company],
          },
        }
      }
      return {
        ...state,
        companyLinks: {
          ...state.companyLinks,
          items: [
            ...state.companyLinks.items.slice(0, activeIndex),
            ...state.companyLinks.items.slice(activeIndex + 1),
          ],
        },
      }
    case SUCCESS(types.CLEAR_COMPANIES):
      return {
        ...state,
        companies: {
          items: [],
          error: '',
          loading: true,
          totalPages: 0,
        },
      }
    case SUCCESS(types.CLEAR_LINKED_COMPANIES):
      return {
        ...state,
        linkedCompanies: {
          items: [],
          loading: true,
          moreLoading: false,
          error: '',
          totalPages: 0,
          total: 0,
        },
      }
    case SUCCESS(types.CLEAR_COMPANY):
      return {
        ...state,
        company: {
          item: null,
          loading: true,
          error: '',
        },
        companyUsers: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
          totalUsers: 0,
        },
        companyCertificates: {
          items: [],
          loading: true,
          error: '',
        },
      }
    case PENDING(types.GET_GLOBAL_SETTINGS):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          loading: true,
        },
      }
    case SUCCESS(types.GET_GLOBAL_SETTINGS):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          items: payload.map((group) => {
            const { length, type } = group
            const [from, to] = length.split('-')

            return { from: Number(from), to: Number(to), type }
          }) || [],
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_GLOBAL_SETTINGS):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_GLOBAL_SETTING):
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          items: [...state.globalSettings.items, payload],
          error: '',
          loading: false,
        },
      }
    case PENDING(types.GET_ATTRIBUTES_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          loading: true,
        },
      }
    case SUCCESS(types.GET_ATTRIBUTES_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          items: payload.data,
          error: '',
          loading: false,
        },
      }
    case SUCCESS(types.CLEAR_ATTRIBUTES_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          items: [],
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_ATTRIBUTES_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.CREATE_ATTRIBUTE_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          loading: true,
        },
      }
    case SUCCESS(types.CREATE_ATTRIBUTE_MAPPING):
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          items: [...state.companyAttributesMappings.items, payload.data],
          error: '',
          loading: false,
        },
      }
    case SUCCESS(types.UPDATE_ATTRIBUTE_MAPPING):
      activeIndex = state.companyAttributesMappings.items.findIndex(
        (el) => el.uuid === payload?.data?.uuid
      )
      newItems = state.companyAttributesMappings.items

      if (activeIndex >= 0) {
        newItems[activeIndex] = payload?.data
      }

      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_ATTRIBUTE_MAPPING):
      activeIndex = state.companyAttributesMappings.items.findIndex(
        (el) => el.uuid === payload
      )
      return {
        ...state,
        companyAttributesMappings: {
          ...state.companyAttributesMappings,
          items: [
            ...state.companyAttributesMappings.items.slice(0, activeIndex),
            ...state.companyAttributesMappings.items.slice(activeIndex + 1),
          ],
        },
      }
    default:
      return state
  }
}

export default companyReducer
