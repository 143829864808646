import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'

const initialState = {
  certificates: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  certificate: {
    item: null,
    loading: true,
    error: '',
    totalPages: 0,
  },
}

const stoneCertificateReducer = (state = initialState, { payload, type }) => {
  let activeIndex = 0
  switch (type) {
    case PENDING(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: true,
        },
      }
    case SUCCESS(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_STONE_CERTIFICATES):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.DELETE_STONE_CERTIFICATE):
      activeIndex = state.certificates.items.findIndex(
        (el) => el?.uuid === payload
      )
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [
            ...state.certificates.items.slice(0, activeIndex),
            ...state.certificates.items.slice(activeIndex + 1)
          ],
        },
      }
    case PENDING(types.GET_STONE_CERTIFICATE):
      return {
        ...state,
        certificate: {
          ...state.certificate,
          loading: true,
        },
      }
    case SUCCESS(types.GET_STONE_CERTIFICATE):
      return {
        ...state,
        certificate: {
          ...state.certificate,
          item: payload.data,
          error: '',
          loading: false,
        },
      }
    case FAILURE(types.GET_STONE_CERTIFICATE):
      return {
        ...state,
        certificate: {
          ...state.certificate,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_STONE_CERTIFICATE):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [...state.certificates.items, payload.data],
        },
      }
    case SUCCESS(types.EDIT_STONE_CERTIFICATE):
      return {
        ...state,
        certificates: {
          ...state.certificates,
          items: [...state.certificates.items, ...payload.data],
        },
      }
    case SUCCESS(types.CLEAR_STONE_CERTIFICATE):
      return {
        ...state,
        certificates: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default stoneCertificateReducer
