import { useEffect } from 'react'

export default function useOutsideClick(
  node,
  onOutsideClick,
  enabled = true,
  useCapture = false
) {
  useEffect(() => {
    function onClick(event) {
      if (
        document.body.contains(event.target) &&
        !node.contains(event.target)
      ) {
        onOutsideClick(event)
      }
    }

    if (node && enabled) {
      document.addEventListener('mousedown', onClick, useCapture)
    }

    return () => {
      document.removeEventListener('mousedown', onClick, useCapture)
    }
  }, [node, onOutsideClick, enabled, useCapture])
}
