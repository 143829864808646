import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CommonMenu.module.scss'

function CommonMenu({ children, className, forwardedRef, ...props }) {
  return (
    <ul
      ref={forwardedRef}
      className={classNames(styles.list, className)}
      onClick={(event) => event.stopPropagation()}
      {...props}
    >
      {children}
    </ul>
  )
}

CommonMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

export default forwardRef((props, ref) => (
  <CommonMenu {...props} forwardedRef={ref} />
))
