import { useEffect, useRef } from "react";

const getContainerEl = attrs => {
    const el = document.createElement("div");

    Object.entries(attrs).forEach(([key, value]) => {
        el.setAttribute(key, value);
    });

    return el;
};

export default function useElementRef(rootRef, attrs = {}) {
    const containerRef = useRef(getContainerEl(attrs));

    useEffect(() => {
        const el = containerRef.current;

        rootRef.current.appendChild(el);

        return () => {
            el?.remove();
        };
    }, [rootRef]);

    return containerRef;
}