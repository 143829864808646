import { useRef } from "react";

const getRootEl = id => {
    let el = document.getElementById(id);

    if (el) {
        return el;
    }

    el = document.createElement("div");
    el.setAttribute("id", id);

    document.body.appendChild(el);

    return el;
};

export default function usePortalRoot(id) {
    if (!id) {
        throw new Error("Portal root id is required");
    }

    return useRef(getRootEl(id));
}