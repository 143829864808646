import stoneCertificates from 'services/stoneCertificates'
import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { clearParams } from 'utils/helpers'
import i18n from 'translations'
import { toast } from 'react-toastify'

const getStoneCertificates =
  ({ params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.GET_STONE_CERTIFICATES) })
      clearParams({ params })
      stoneCertificates
        .getStoneCertificates({ params })
        .then((res) =>
          dispatch({ type: SUCCESS(types.GET_STONE_CERTIFICATES), payload: res })
        )
        .catch(() =>
          dispatch({
            type: FAILURE(types.GET_STONE_CERTIFICATES),
          })
        )
    }

const getStoneCertificate =
  ({ id, params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.GET_STONE_CERTIFICATE) })
      clearParams({ params })
      stoneCertificates
        .getStoneCertificate({ id, params })
        .then((res) =>
          dispatch({ type: SUCCESS(types.GET_STONE_CERTIFICATE), payload: res })
        )
        .catch(() =>
          dispatch({
            type: FAILURE(types.GET_STONE_CERTIFICATE),
          })
        )
    }

const createStoneCertificate =
  ({ data, params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.CREATE_STONE_CERTIFICATE) })
      return stoneCertificates
        .createStoneCertificates({ data, params })
        .then((res) => {
          toast.success(
            `
            ${i18n.t('product:stone_certificate')} ${i18n.t('common:create_success')}
            `
          )
          dispatch({ type: SUCCESS(types.CREATE_STONE_CERTIFICATE), payload: res })
          return res
        })
        .catch((err) => {
          dispatch({
            type: FAILURE(types.CREATE_STONE_CERTIFICATE),
          })
          throw err
        })
    }

const deleteStoneCertificate =
  ({ id, params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.DELETE_STONE_CERTIFICATE) })
      return stoneCertificates
        .deleteStoneCertificate({ id, params })
        .then(() => {
          dispatch({
            type: SUCCESS(types.DELETE_STONE_CERTIFICATE),
            payload: id,
          })
        })
        .catch((err) => {
          dispatch({
            type: FAILURE(types.DELETE_STONE_CERTIFICATE),
            payload: err?.response?.message,
          })
          throw err
        })
    }

const deleteStoneCertificateFile =
  ({ id, params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.DELETE_STONE_CERTIFICATE_FILE) })
      return stoneCertificates
        .deleteStoneCertificateFile({ id, params })
        .then(() => {
          dispatch({
            type: SUCCESS(types.DELETE_STONE_CERTIFICATE_FILE),
          })
        })
        .catch((err) => {
          dispatch({
            type: FAILURE(types.DELETE_STONE_CERTIFICATE_FILE),
          })
          throw err
        })
    }

const editStoneCertificate =
  ({ data, id, params }) =>
    (dispatch) => {
      dispatch({ type: PENDING(types.EDIT_STONE_CERTIFICATE) })
      return stoneCertificates
        .updateStoneCertificate({ data, id, params })
        .then((res) => {
          toast.success(
            `${i18n.t('product:stone_certificate')} ${i18n.t('common:edit_success')}`
          )
          dispatch({ type: SUCCESS(types.EDIT_STONE_CERTIFICATE), payload: res })
        })
        .catch(() =>
          dispatch({
            type: FAILURE(types.EDIT_STONE_CERTIFICATE),
          })
        )
    }

const uploadStoneCertificateFile =
  ({ data, id }) =>
    (dispatch) => {
      dispatch({ type: SUCCESS(types.CLEAR_STONE_CERTIFICATE) })
      dispatch({ type: PENDING(types.UPLOAD_STONE_CERTIFICATE_FILE) })
      return stoneCertificates
        .uploadStoneCertificateFile({ data, id })
        .then((res) => {
          dispatch({
            type: SUCCESS(types.UPLOAD_STONE_CERTIFICATE_FILE),
            payload: { data: res.data, id },
          })
          return res.data
        })
        .catch((err) => {
          dispatch({
            type: FAILURE(types.UPLOAD_STONE_CERTIFICATE_FILE),
            payload: err,
          })
          throw err
        })
    }

const clearStoneCertificates = () => (dispatch) =>
  dispatch({ type: SUCCESS(types.CLEAR_STONE_CERTIFICATE) })

export default {
  getStoneCertificates,
  getStoneCertificate,
  createStoneCertificate,
  clearStoneCertificates,
  deleteStoneCertificate,
  editStoneCertificate,
  uploadStoneCertificateFile,
  deleteStoneCertificateFile
}
