const GET_COMPANIES = 'GET_COMPANIES'
const GET_MORE_COMPANIES = 'GET_MORE_COMPANIES'
const GET_COMPANY = 'GET_COMPANY'
const UPDATE_COMPANY = 'UPDATE_COMPANY'
const CREATE_COMPANY = 'CREATE_COMPANY'
const DELETE_COMPANY = 'DELETE_COMPANY'

const GET_SHARED_COMPANIES = 'GET_SHARED_COMPANIES'
const GET_MORE_SHARED_COMPANIES = 'GET_MORE_SHARED_COMPANIES'

const UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO'
const REMOVE_COMPANY_LOGO = 'REMOVE_COMPANY_LOGO'

const UPLOAD_COMPANY_FILES = 'UPLOAD_COMPANY_FILES'
const DELETE_COMPANY_FILES = 'DELETE_COMPANY_FILES'

const GET_COMPANIES_LETTERS = 'GET_COMPANIES_LETTERS'

const CLEAR_COMPANIES = 'CLEAR_COMPANIES'
const CLEAR_LINKED_COMPANIES = 'CLEAR_LINKED_COMPANIES'
const CLEAR_COMPANY = 'CLEAR_COMPANY'

const GET_LINKED_COMPANIES = 'GET_LINKED_COMPANIES'
const GET_MORE_LINKED_COMPANIES = 'GET_MORE_LINKED_COMPANIES'

const GET_COMPANY_USERS = 'GET_COMPANY_USERS'

const GET_COMPANY_CERTIFICATES = 'GET_COMPANY_CERTIFICATES'
const CREATE_COMPANY_CERTIFICATE = 'CREATE_COMPANY_CERTIFICATE'
const DELETE_COMPANY_CERTIFICATE = 'DELETE_COMPANY_CERTIFICATE'
const EDIT_COMPANY_CERTIFICATE = 'EDIT_COMPANY_CERTIFICATE'

const UPLOAD_COMPANY_CERTIFICATE_FILES = 'UPLOAD_COMPANY_CERTIFICATE_FILES'
const DELETE_COMPANY_CERTIFICATE_FILES = 'DELETE_COMPANY_CERTIFICATE_FILES'

const CREATE_LINK_REQUEST = 'CREATE_LINK_REQUEST'
const GET_COMPANY_LINKS = 'GET_COMPANY_LINKS'
const APPROVE_COMPANY_LINK = 'APPROVE_COMPANY_LINK'
const DECLINE_COMPANY_LINK = 'DECLINE_COMPANY_LINK'

const UPDATE_COMPANY_IMPORT_SETTINGS = 'UPDATE_COMPANY_IMPORT_SETTINGS'
const CLEAR_COMPANY_IMPORT_SETTINGS = 'CLEAR_COMPANY_IMPORT_SETTINGS'

const GET_ATTRIBUTES_MAPPING = 'GET_ATTRIBUTES_MAPPING'
const CREATE_ATTRIBUTE_MAPPING = 'CREATE_ATTRIBUTE_MAPPING'
const SHOW_ATTRIBUTE_MAPPING = 'SHOW_ATTRIBUTE_MAPPING'
const UPDATE_ATTRIBUTE_MAPPING = 'UPDATE_ATTRIBUTE_MAPPING'
const DELETE_ATTRIBUTE_MAPPING = 'DELETE_ATTRIBUTE_MAPPING'
const CLEAR_ATTRIBUTES_MAPPING = 'CLEAR_ATTRIBUTES_MAPPING'

const GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS'
const CREATE_GLOBAL_SETTING = 'CREATE_GLOBAL_SETTING'

export default {
  GET_COMPANIES,
  GET_MORE_COMPANIES,
  GET_COMPANIES_LETTERS,
  CLEAR_COMPANIES,
  GET_LINKED_COMPANIES,
  GET_SHARED_COMPANIES,
  GET_MORE_SHARED_COMPANIES,
  GET_MORE_LINKED_COMPANIES,
  GET_COMPANY_USERS,
  GET_COMPANY,
  DELETE_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  UPLOAD_COMPANY_LOGO,
  REMOVE_COMPANY_LOGO,
  UPLOAD_COMPANY_FILES,
  DELETE_COMPANY_FILES,
  GET_COMPANY_CERTIFICATES,
  CREATE_COMPANY_CERTIFICATE,
  DELETE_COMPANY_CERTIFICATE,
  EDIT_COMPANY_CERTIFICATE,
  UPLOAD_COMPANY_CERTIFICATE_FILES,
  DELETE_COMPANY_CERTIFICATE_FILES,
  CREATE_LINK_REQUEST,
  CLEAR_LINKED_COMPANIES,
  GET_COMPANY_LINKS,
  APPROVE_COMPANY_LINK,
  DECLINE_COMPANY_LINK,
  CLEAR_COMPANY,
  UPDATE_COMPANY_IMPORT_SETTINGS,
  GET_ATTRIBUTES_MAPPING,
  CREATE_ATTRIBUTE_MAPPING,
  SHOW_ATTRIBUTE_MAPPING,
  UPDATE_ATTRIBUTE_MAPPING,
  DELETE_ATTRIBUTE_MAPPING,
  GET_GLOBAL_SETTINGS,
  CREATE_GLOBAL_SETTING,
  CLEAR_ATTRIBUTES_MAPPING,
  CLEAR_COMPANY_IMPORT_SETTINGS
}
