import { filesToFormData } from 'utils/serializer'
import { formDataHeaders, requests } from './api'

const initialEndpoint = 'stone-certificates'

const getStoneCertificates = ({ params }) =>
  requests.get({ url: initialEndpoint, params }).then((res) => res.data)

const createStoneCertificates = ({ data, params }) =>
  requests.post({ url: initialEndpoint, data, params }).then((res) => res.data)

const getStoneCertificate = ({ id, params }) =>
  requests.get({ url: `${initialEndpoint}/${id}`, params }).then((res) => res.data)

const updateStoneCertificate = ({ id, params, data }) =>
  requests.put({ url: `${initialEndpoint}/${id}`, params, data }).then((res) => res.data)

const deleteStoneCertificate = ({ id, params }) =>
  requests.delete({ url: `${initialEndpoint}/${id}`, params }).then((res) => res.data)

const uploadStoneCertificateFile = ({ id, params, data }) =>
  requests.post({
    url: `${initialEndpoint}/${id}/files`,
    params,
    data: filesToFormData(data),
    headers: formDataHeaders,
  })

const deleteStoneCertificateFile = ({ id, params }) =>
  requests.delete({ url: `${initialEndpoint}/${id}/files`, params }).then((res) => res.data)


export default {
  getStoneCertificates,
  createStoneCertificates,
  getStoneCertificate,
  updateStoneCertificate,
  deleteStoneCertificate,
  uploadStoneCertificateFile,
  deleteStoneCertificateFile
}
