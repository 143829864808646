export default {
  orders: {
    name: 'Orders',
    new: 'Create New Order',
    empty_description: 'There is no orders yet',
    product_order_number: 'Product Order Number',
    sent_date: 'Sent date',
    manufacturer: 'Manufacturer',
    brand: 'Brand',
    order_status: 'Order Status',
    client: 'Client',
    sent: 'Sent',
    draft: 'Draft',
    delete_title: 'You are about to delete an order',
    delete_description: 'This will delete the order from catalog',
    due_date: 'Due date',
    info: 'Order Info',
    order: 'Order',
    products: 'Order Products',
    add_product: 'Add Product',
    search_placeholder: 'Search reference or Serial Number',
    delete_product_title: 'You are about to remove a product',
    delete_product_description: 'This will remove the product from order',
    empty_filter_description:
      'There are no orders under this category right now',
    add_title: 'Add a Product to Order',
    edit_title: 'Edit Order Product',
    send: 'Send Product Order',
    send_title: 'You are about to send the order',
    send_description: 'This will send the order',
    imports: 'Imports',
    order_imports: 'Order imports',
    has_errors: 'Has Errors',
    processed: 'Processed',
  },
  reports: {
    report: 'Report',
    save_report: 'Save Report',
    name: 'Reports',
    info: 'Quality Control Report Information',
    new: 'Create New Report',
    send: 'Send Report',
    send_title: 'You are about to send the report',
    send_description: 'This will send the report',
    report_name: 'Report Name',
    most_failed: 'Most Failed Product',
    most_frequent: 'Most Frequent Defects',
    most_failed_collection: 'Most Failed Collection',
    list_search_placeholder: 'Search Report ID, Name, Reference, Serial Number',
    delete_title: 'You are about to delete the report',
    delete_description: 'This will delete the report from catalog',
    empty_description: 'There is no reports yet',
    failures: 'failures',
    defects: 'defects',
    report_product: 'Report product',
    synchronize: 'Synchronize Orders',
    sn: 'SN',
    set_status: 'Set status',
    show_control_info: 'Show Control Info',
    hide_control_info: 'Hide Control Info',
    delete_product_title: 'You are about to remove the product from the report',
    delete_product_description:
      'This action will remove the product from the report',
    stone_certificates: 'Stone Certificates',
    stone_certificates_empty: 'There are no stone certificates yet',
    order_to_sync: 'Select order to synchronize',
    sync_search: 'Search Order ID, Name, Reference, Serial Number',
    select_products: 'Select products',
    product_history: 'Product History',
    problems: 'Problems',
    total_controlled: 'Total controlled',
    controlled: 'Controlled',
    empty_list_message: 'There are no product history records yet',
  },
  templates: {
    new: 'Create New Template',
    empty_description: 'There is no templates yet',
    template_name: 'Template Name',
    template: 'Template',
    custom_fields: 'Custom Fields',
    add_custom_field: 'Add Custom Field',
    empty_description_fields: 'There is no fields yet',
    delete: 'Delete Template',
    new_custom_field: 'New Custom Field',
    field_label: 'Field Title (Label)',
    field_placeholder: 'Enter the name of the field',
    field_type: 'Field Type',
    field_type_placeholder: 'what type the field is',
    custom_placeholder: 'Custom placeholder',
    placeholder: 'Placeholder',
    option_placeholder: 'Enter the name of the option',
    add_option: 'Add Option',
    remove_option: 'Remove Option',
    delete_title: 'You are about to delete the template',
    delete_description: 'This will delete the tepmplate from the system',
    search_fields: 'Search custom field',
    company_fields: 'Company Fields',
    ionaute_fields: 'Ionaute Fields',
    is_field_required: 'Is field required?',
    remove_field_title: 'You are about to remove the field',
    remove_field_description: 'This will remove the field from the template',
    options: 'Options',
    field: 'Field',
  },
}
