import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { authSelectors, authActions } from 'store/ducks/auth'
import { commonActions, commonSelectors } from 'store/ducks/common'

import { THEMES } from 'constants/configs'
import routes, { COMPANY, USER } from 'constants/routes'

import Breadcrumbs from './Breadcrumbs'
import GlobalSearch from './GlobalSearch'
import Toggle from 'components/buttons/Toggle'
import Avatar from 'components/Avatar'
import CommonMenu from '../common/CommonMenu'
import MenuItem from '../common/CommonMenu/MenuItem'
import CommonDropdown from '../common/CommonDropdown'

import profileIcon from 'assets/icons/common/profile.svg'
import logoutIcon from 'assets/icons/common/logout.svg'

import styles from './Navbar.module.scss'

const Navbar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, isAdmin } = useSelector(authSelectors.getAuthInfo)

  const activeTheme = useSelector(commonSelectors.getActiveTheme)

  const navigateToProfilePage = () =>
    navigate(
      isAdmin
        ? `${routes.companyManagement.root}/${COMPANY}/${user?.company?.uuid}/${USER}/${user?.uuid}`
        : `${routes.myCompany.root}/${USER}/${user?.uuid}`
    )

  return (
    <div className={styles.navbarContainer}>
      <Breadcrumbs />
      <div className={styles.navbarContainer_row}>
        <div className={styles.navbarContainer_search}>
          <GlobalSearch />
        </div>
        <CommonDropdown
          trigger={
            <div>
              <Avatar
                image={user?.profile}
                firstname={user?.firstname}
                lastname={user?.lastname}
              />
            </div>
          }
        >
          {({ toggleDropdown }) => (
            <CommonMenu>
              <MenuItem>
                <Toggle
                  onChange={() => dispatch(commonActions.setTheme())}
                  isChecked={activeTheme === THEMES.dark}
                  id="themeSwitch"
                />
                <label htmlFor="themeSwitch" className={styles.switchLabel}>
                  {t('common:darkMode')}
                </label>
              </MenuItem>
              <MenuItem
                icon={profileIcon}
                onClick={() => {
                  navigateToProfilePage()
                  toggleDropdown()
                }}
                className={styles.row}
              >
                {t('common:profile')}
              </MenuItem>
              <MenuItem
                icon={logoutIcon}
                onClick={() => dispatch(authActions.logout())}
                className={styles.row}
              >
                {t('common:logout')}
              </MenuItem>
            </CommonMenu>
          )}
        </CommonDropdown>
      </div>
    </div>
  )
}

export default Navbar
