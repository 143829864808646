const getCompanies = (state) => state.company.companies.items
const getCompaniesLoading = (state) => state.company.companies.loading
const getCompaniesError = (state) => state.company.companies.error
const getCompaniesPages = (state) => state.company.companies.totalPages
const getCompaniesMoreLoading = (state) =>
  state.company.linkedCompanies.moreLoading

const getCompanyInfo = (state, id) => {
  if (id) {
    return state.company.company
  }
  return { item: state.auth.user?.company, loading: false }
}

const getCompaniesLetters = (state) => state.company.companiesLetters.items
const getCompaniesLettersLoading = (state) =>
  state.company.companiesLetters.loading
const getCompaniesLettersError = (state) => state.company.companiesLetters.error
const getFirstLetter = (state) => state.company.companiesLetters.items[0] || ''

const getLinkedCompanies = (state) => state.company.linkedCompanies.items
const getLinkedCompaniesError = (state) => state.company.linkedCompanies.error
const getLinkedCompaniesLoading = (state) =>
  state.company.linkedCompanies.loading
const getLinkedCompaniesMoreLoading = (state) =>
  state.company.linkedCompanies.moreLoading
const getLinkedCompaniesPages = (state) =>
  state.company.linkedCompanies.totalPages
const getLinkedCompaniesTotal = (state) => state.company.linkedCompanies.total

const getCompaniesUsers = (state) => state.company.companyUsers.items
const getCompaniesUsersLoading = (state) => state.company.companyUsers.loading
const getCompaniesUsersError = (state) => state.company.companyUsers.error
const getCompaniesUsersPages = (state) => state.company.companyUsers.totalPages
const getCompaniesUsersTotal = (state) => state.company.companyUsers.totalUsers

const getCompanyCertificates = (state) =>
  state.company.companyCertificates.items
const getCompanyCertificatesLoading = (state) =>
  state.company.companyCertificates.loading

const getCompanyLinks = (state) => state.company.companyLinks

const getSharedCompaniesInfo = (state) => state.company.sharedCompanies

const getCompanyAttributesMappings = (state) =>
  state.company.companyAttributesMappings

const getCompanyGlobalSettings = (state) =>
  state.company.globalSettings

export default {
  getCompanies,
  getCompaniesLoading,
  getCompaniesError,
  getCompaniesMoreLoading,
  getCompaniesLetters,
  getCompaniesLettersLoading,
  getCompaniesLettersError,
  getCompaniesPages,
  getFirstLetter,
  getLinkedCompanies,
  getLinkedCompaniesError,
  getLinkedCompaniesLoading,
  getLinkedCompaniesMoreLoading,
  getLinkedCompaniesPages,
  getLinkedCompaniesTotal,
  getCompaniesUsers,
  getCompaniesUsersLoading,
  getCompaniesUsersError,
  getCompaniesUsersPages,
  getCompaniesUsersTotal,
  getCompanyCertificates,
  getCompanyCertificatesLoading,
  getSharedCompaniesInfo,
  getCompanyLinks,
  getCompanyInfo,
  getCompanyAttributesMappings,
  getCompanyGlobalSettings
}
