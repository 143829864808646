import types from './actionTypes'
import { PENDING, SUCCESS, FAILURE } from 'constants/configs'
import { productTypes } from 'store/ducks/product'

const initialState = {
  productionOrders: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  activeOrder: {
    item: null,
    loading: true,
    error: '',
  },
  orderProducts: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    total: 0,
  },
  productionOrderCollections: {
    items: [],
    loading: true,
    moreLoading: false,
    error: '',
    totalPages: 0,
  },
  orderVariants: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
  orderSerials: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
    total: 0,
  },
  orderImports: {
    items: [],
    loading: true,
    error: '',
    totalPages: 0,
  },
}

const productionOrderReducer = (state = initialState, { payload, type }) => {
  let index
  switch (type) {
    case PENDING(types.GET_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          items: [...state.productionOrders.items, ...payload.data],
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          moreLoading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_PRODUCTION_ORDER):
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          items: [...state.productionOrders.items, payload.data],
        },
      }
    case SUCCESS(types.EDIT_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          item: payload.data,
        },
      }
    case SUCCESS(types.DELETE_PRODUCTION_ORDER):
      index = state.productionOrders.items.findIndex(
        (el) => el.uuid === payload
      )
      return {
        ...state,
        productionOrders: {
          ...state.productionOrders,
          items: [
            ...state.productionOrders.items.slice(0, index),
            ...state.productionOrders.items.slice(index + 1),
          ],
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          item: payload.data,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER_PRODUCTS):
      return {
        ...state,
        orderProducts: {
          ...state.orderProducts,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER_PRODUCTS):
      return {
        ...state,
        orderProducts: {
          ...state.orderProducts,
          items: payload.data,
          totalPages: payload.meta.last_page,
          total: state.orderProducts.total || payload.meta.total,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER_PRODUCTS):
      return {
        ...state,
        orderProducts: {
          ...state.orderProducts,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_MORE_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          moreLoading: true,
        },
      }
    case SUCCESS(types.GET_MORE_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          items: [...state.productionOrderCollections.items, ...payload.data],
          moreLoading: false,
        },
      }
    case FAILURE(types.GET_MORE_PRODUCTION_ORDER_COLLECTIONS):
      return {
        ...state,
        productionOrderCollections: {
          ...state.productionOrderCollections,
          moreLoading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER_VARIANTS):
      return {
        ...state,
        orderVariants: {
          ...state.orderVariants,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER_VARIANTS):
      return {
        ...state,
        orderVariants: {
          ...state.orderVariants,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER_VARIANTS):
      return {
        ...state,
        orderVariants: {
          ...state.orderVariants,
          loading: false,
          error: payload,
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER_SERIALS):
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER_SERIALS):
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          items: payload.data.data,
          totalPages: payload.data.meta.last_page,
          loading: false,
          total: state.orderSerials.total || payload.data.meta.total,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER_SERIALS):
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.CREATE_PRODUCTION_ORDER_SERIAL):
      index = state.orderProducts.items.findIndex(
        (el) => el.uuid === payload.productId
      )
      state.orderProducts.items[index] = {
        ...state.orderProducts.items[index],
        instances_count:
          state.orderProducts.items[index]?.instances_count +
          payload?.data?.length,
        product_quantity:
          state.orderProducts.items[index].product_quantity <
            state.orderProducts.items[index].instances_count +
            payload?.data?.length
            ? state.orderProducts.items[index].instances_count +
            payload?.data?.length
            : state.orderProducts.items[index].product_quantity,
      }
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          items: [...state.orderSerials.items, ...payload.data],
          total: state.orderSerials.total + payload.data.length,
          totalPages:
            state.orderSerials?.totalPages +
            Math.floor(payload.data.length / 10),
        },
        orderProducts: {
          ...state.orderProducts,
          items: [...state.orderProducts.items],
        },
      }
    case SUCCESS(types.DELETE_PRODUCTION_ORDER_SERIAL):
      const productIndex = state.orderProducts.items.findIndex(
        (el) => el.uuid === payload.productId
      )
      if (productIndex >= 0) {
        state.orderProducts.items[productIndex] = {
          ...state.orderProducts.items[productIndex],
          instances_count:
            state.orderProducts.items[productIndex]?.instances_count - 1,
        }
      }
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          items: [
            ...state.orderSerials.items.slice(0, payload.index),
            ...state.orderSerials.items.slice(payload.index + 1),
          ],
          total: state.orderSerials.total - 1,
        },
        orderProducts: {
          ...state.orderProducts,
          items: [...state.orderProducts.items],
        },
      }
    case SUCCESS(productTypes.EDIT_PRODUCT_INSTANCE):
      const newItems = state.orderSerials.items
      index = newItems.findIndex(
        (el) => el?.instance?.uuid === payload.instanceId
      )
      if (index >= 0) {
        newItems[index] = {
          ...newItems[index],
          instance: {
            ...newItems[index].instance,
            comment: payload.data?.comment,
          },
        }
      }
      return {
        ...state,
        orderSerials: {
          ...state.orderSerials,
          items: [...newItems],
        },
      }
    case SUCCESS(types.DELETE_PRODUCTION_ORDER_PRODUCT):
      index = state.orderProducts.items.findIndex((el) => el.uuid === payload)
      return {
        ...state,
        orderProducts: {
          ...state.orderProducts,
          items: [
            ...state.orderProducts.items.slice(0, index),
            ...state.orderProducts.items.slice(index + 1),
          ],
        },
      }
    case SUCCESS(types.CREATE_PRODUCTION_ORDER_PRODUCT):
      const collection = state.productionOrderCollections.items.find(
        (el) => el.uuid === payload.data.collection.uuid
      )
      const presentedOrderProduct = state.orderProducts.items.find(orderProduct => orderProduct.uuid === payload.data.uuid)
      if (presentedOrderProduct) {
        return {
          ...state,
          orderProducts: {
            ...state.orderProducts,
            items: [...state.orderProducts.items.reduce((acc, orderProduct) => {
              if (orderProduct.uuid === payload.data.uuid) {
                return [...acc, { ...orderProduct, ...payload.data }];
              }
              return [...acc, orderProduct];
            }, [])]
          },
          productionOrderCollections: {
            ...state.productionOrderCollections,
            items: collection
              ? state.productionOrderCollections.items
              : [
                ...state.productionOrderCollections.items,
                payload.data.collection,
              ],
          },
        }
      } else {
        return {
          ...state,
          orderProducts: {
            ...state.orderProducts,
            items: [...state.orderProducts.items, payload.data],
          },
          productionOrderCollections: {
            ...state.productionOrderCollections,
            items: collection
              ? state.productionOrderCollections.items
              : [
                ...state.productionOrderCollections.items,
                payload.data.collection,
              ],
          },
        }
      }
    case SUCCESS(types.EDIT_PRODUCTION_ORDER_PRODUCT):
      index = state.orderProducts.items.findIndex(
        (el) => el.uuid === payload.data.uuid
      )
      state.orderProducts.items[index] = payload.data
      return {
        ...state,
        orderProducts: {
          ...state.orderProducts,
          items: [...state.orderProducts.items],
        },
      }
    case SUCCESS(types.SEND_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          ...state.activeOrder,
          item: payload,
        },
      }
    case PENDING(types.GET_PRODUCTION_ORDER_IMPORTS):
      return {
        ...state,
        orderImports: {
          ...state.orderImports,
          loading: true,
        },
      }
    case SUCCESS(types.GET_PRODUCTION_ORDER_IMPORTS):
      return {
        ...state,
        orderImports: {
          ...state.orderImports,
          items: payload.data,
          totalPages: payload.meta.last_page,
          loading: false,
        },
      }
    case FAILURE(types.GET_PRODUCTION_ORDER_IMPORTS):
      return {
        ...state,
        orderImports: {
          ...state.orderImports,
          loading: false,
          error: payload,
        },
      }
    case SUCCESS(types.IMPORT_PRODUCTION_ORDER):
      return {
        ...state,
        orderImports: {
          ...state.orderImports,
          items: [...state.orderImports.items, payload],
        },
      }
    case SUCCESS(types.CLEAR_PRODUCTION_ORDER_SERIALS):
      return {
        ...state,
        orderSerials: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
          total: 0,
        },
      }
    case SUCCESS(types.CLEAR_PRODUCTION_ORDER):
      return {
        ...state,
        activeOrder: {
          item: null,
          loading: true,
          error: '',
        },
        orderProducts: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
        productionOrderCollections: {
          items: [],
          loading: true,
          moreLoading: false,
          error: '',
          totalPages: 0,
        },
        orderVariants: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
        orderSerials: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
          total: 0,
        },
      }
    case SUCCESS(types.CLEAR_PRODUCTION_ORDERS):
      return {
        ...state,
        productionOrders: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
          moreLoading: false,
        },
      }
    case SUCCESS(types.CLEAR_PRODUCTION_ORDER_IMPORTS):
      return {
        ...state,
        orderImports: {
          items: [],
          loading: true,
          error: '',
          totalPages: 0,
        },
      }
    default:
      return state
  }
}

export default productionOrderReducer
