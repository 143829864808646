import React, { useMemo, useState, useCallback } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import usePortalRoot from '../../../utils/hooks/usePortalRoot'
import useOutsideClick from '../../../utils/hooks/useOutsideClick'
import useElementRef from '../../../utils/hooks/useElementRef'
import styles from './CommonDropdown.module.scss'

export const DROPDOWN_PORTAL_ROOT = 'dropdown-root'

export default function CommonDropdown({
  closeMenuOnOutsideClick = true,
  menuPlacement = 'bottom-start',
  trigger,
  onToggle,
  children,
  modifiers = [],
  strategy,
  onClick,
  className,
  isOpened,
  ...props
}) {
  const [popper, setPopper] = useState(null)
  const [reference, setReference] = useState(null)
  const [localIsExpanded, setIsExpanded] = useState(false)
  const { styles: popperStyles, attributes } = usePopper(reference, popper, {
    placement: menuPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      ...modifiers,
    ].filter(Boolean),
    strategy,
  })

  const portalRootRef = usePortalRoot(DROPDOWN_PORTAL_ROOT)
  const portalNode = useElementRef(portalRootRef)

  const isExpanded = isOpened ?? localIsExpanded

  const toggle = useCallback(
    (force) => {
      const value = force !== undefined ? force : !isExpanded

      setIsExpanded(value)
      onToggle?.(value)

      return value
    },
    [isExpanded, onToggle]
  )

  useOutsideClick(
    popper,
    ({ target }) => {
      if (!reference.contains(target)) {
        toggle(false)
      }
    },
    isExpanded && closeMenuOnOutsideClick,
    true
  )

  const decoratedTrigger = useMemo(
    () =>
      React.cloneElement(trigger, {
        onClick: (event) => {
          const { onClick: onTriggerClick } = trigger.props
          const value = toggle()

          onTriggerClick?.(event, value)
        },
        ref: setReference,
      }),
    [toggle, trigger]
  )

  return (
    <>
      <div className={styles.triggerContainer}>{decoratedTrigger}</div>
      {isExpanded &&
        createPortal(
          <div
            style={popperStyles.popper}
            {...attributes.popper}
            ref={setPopper}
            onClick={(event) => {
              onClick?.(event)
              toggle(false)
            }}
            {...props}
            className={classNames(styles.dropdown, className)}
          >
            <div>
              {typeof children === 'function'
                ? children({ toggleDropdown: toggle })
                : children}
            </div>
          </div>,
          portalNode.current
        )}
    </>
  )
}

CommonDropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  closeMenuOnOutsideClick: PropTypes.bool,
  menuPlacement: PropTypes.string,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  modifiers: PropTypes.array,
  strategy: PropTypes.string,
  onClick: PropTypes.func,
  isOpened: PropTypes.bool,
}
